import Vue from 'vue'
import VueRouter from 'vue-router'
const Index = resolve => require(['@/views/index/index.vue'], resolve)

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Index',
        component: Index
    },
    {
        path: '/product',
        name:'product',
        component: resolve => require(['@/views/products/product.vue'], resolve)
    },
    {
        path:'/detail',
        name:'detail',
        component: resolve => require(['@/views/products/detail.vue'], resolve),
    },
    {
        path:'/aboutUs',
        name:'aboutUs',
        component:resolve => require(['@/views/aboutUs/index.vue'], resolve)
    },
    {
        path:'/news',
        name:'news',
        component:resolve => require(['@/views/news/news.vue'], resolve)
    },
    {
        path:'/photo',
        name:'photo',
        component:resolve => require(['@/views/photo/photo.vue'], resolve)
    },
    {
        path:'/partner',
        name:'partner',
        component:resolve => require(['@/views/partner/partner.vue'], resolve)
    }
    ]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
