<template>
  <div id="app">
    <div>
        <div class="nav">
            <img src="./assets/images/logo1.png" alt="">
            <div class="navList">
                <div class="listItme" @click="navClick(item.id,index)" :class="current == index?'active':''"  v-for="(item,index)  in navList" :key="item.id">{{item.name}}</div>
            </div>
        </div>
        <router-view></router-view>
        <footer-bom></footer-bom>
    </div>
  </div>
</template>

<script>
import footerBom from '@/components/footerBom.vue'
export default {
     components: { footerBom },
    data(){
        return{
            current:0,
            navList:[
                {id:'index',name:'网站首页'},
                {id:'aboutUs',name:'关于我们'},
                {id:'products',name:'产品展示'},
                {id:'news',name:'新闻资讯'},
                {id:'photo',name:'企业相册'},
                {id:'partner',name:'合作伙伴'},
            ]
        }
    },
    watch:{
        '$route':function(to,from){
            console.log(to.path)
            if(to.path == '/index' || to.path =='/'){
                this.current = 0
            }else if(to.path == '/aboutUs'){
                this.current = 1
            }else if(to.path == '/product'){
                this.current = 2
            }else if(to.path == '/news'){
                this.current = 3
            }else if(to.path == '/photo'){
                this.current = 4
            }else if(to.path == '/partner'){
                this.current = 5
            }else if(to.path == '/detail'){
                this.current = 2
            }
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        }
    },
    methods:{
        navClick(id,index){
            this.current = index
            if(index == 0){
                this.$router.push('/')
            }else if(index == 1){
                this.$router.push('aboutUs')
            }else if(index == 2) {
                this.$router.push('/product')
            }else if(index == 3) {
                this.$router.push('/news')
            }else if(index == 4){
                this.$router.push('/photo')
            }else{
                this.$router.push('/partner')
            }
            
        }
    }
}
</script>
<style lang="scss">
@import "assets/css/default.css";
@import "assets/css/index.css";
.nav{
    position: fixed;
    top:0;
    left:0;
    z-index: 999;
    background: rgba($color: #fff, $alpha: 1);
    height:80px;
    width:100%;
    display: flex;
    justify-content: space-around;
    align-content: center;
    img{
        height: 55px;
        margin-top: 14px;
    }
    .navList{
        cursor: pointer;
        display: flex;
        justify-content: space-around;
        align-content: center;
        overflow: hidden;
        
        .listItme{
            height: 100%;
            line-height: 40px;
            text-align: center;
            padding:20px;
            box-sizing: border-box;
            margin-right: 5px;
            transition: all 1s;
            &:hover{
                background: #90bef1;
                color: #fff;
                font-size: 16px;
                transform: scale(1.1);
            }
        }
        .active{
            background: #627afd;
            color:#fff;
        }
    }
}
</style>
