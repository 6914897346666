<template>
   <div class="footer">
       <div class="main">
           <div class="footer_wrap">
               <div>
                    <div class="contactUs">联系我们</div>
                    <div class="address">地址：湖北省武汉市洪山区光谷创业街特1栋1单元2201室 </div>
                    <div class="address">
                        <span>电话：13387507805</span>
                        <span class="marL20">传真：027-87440776</span>
                    </div>
                    <div class="address">
                        <span>邮箱：whsjxckj@163.com </span>
                        <span class="marL20">QQ：792859496 </span>
                    </div>
                    <div class="address">工作时间：周一 到 周五 8:30--17:30 </div>
                    <div class="address">24小时售后服务热线：13308657805 </div>
                </div>
                <div class="QRcode">
                    <div class="QR">
                        <img src="@/assets/images/erweima.jpg" alt="" width="145px">
                        <div class="name">企业微信</div>
                    </div>
                    <div class="QR">
                        <img src="@/assets/images/erweima.jpg" alt="" width="145px">
                        <div class="name">个人微信</div>
                    </div>
                </div>
           </div>
       </div>
        <div class="footerBottom">Wuhan Century Star Technology Co., Ltd 武汉世纪星辰科技有限公司  |  
           <a target="_blank" style="color:#fff;text-decoration:none" href="https://beian.miit.gov.cn">鄂ICP备2021022259号</a>
        </div>
   </div>
</template>

<script>
export default {
   data() {
       return {};
   },

   components: {},

   computed:{},

   mounted () {},

   methods: {},
}
</script>

<style lang='scss' scoped>
.footer{
    position: relative;
    width: 100%;
    height: 360px;
    background: #22293E;
    .main{
        margin: 0 auto;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 1200px;
        .footer_wrap{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            color:#fff;
            .contactUs{
                font-size: 16px;
                line-height: 28px;
                margin-bottom: 16px;
                font-weight: bold;
            }
            .address{
               font-size: 14px;
                line-height: 34px;
                span{
                    width:198px;
                    display: inline-block;
                }
                .marL20{
                    margin-left:20px;
                }
            }
            .QRcode{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                .QR{
                   &:nth-child(2){
                        margin:0 40px;
                    } 
                    .name{
                        text-align: center;
                        margin-top:24px;
                    }
                }
                
            }
        }
    }
    .footerBottom{
        position: absolute;
        left:0;
        bottom: 0;
        width: 100%;
        height: 32px;
        line-height: 32px;
        font-size: 12px;
        font-weight: 400;
        text-align: center;
        color: #FFFFFF;
        background: #0F162BFF;
    }
}
</style>